<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="purchaseOtherInvoice"
          ref="formEdit"
          :forwarders="forwarders"
          :accounts="accounts"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :item="purchaseOtherInvoice" :spk="spk" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && purchaseOtherInvoice.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="openUpdateTaxDate">
                  <v-icon>
                    mdi-update
                  </v-icon>
                </v-btn>
              </template>
              <span>Update Tax Date</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && purchaseOtherInvoice.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="dialogDelete">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="purchaseOtherInvoice.invoiceNumber"
      title="Forwarder Invoice"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
    <dialog-update-tax-date
      :dialog="dialogTax"
      :form="purchaseOtherInvoice"
      @save="updateTaxDate"
      @close="close"
    ></dialog-update-tax-date>
  </form>
</template>

<script>
import Detail from "./DetailPurchaseOtherInvoice";
import FormEdit from "./FormPurchaseOtherInvoice";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";
import DialogUpdateTaxDate from "@/components/DialogUpdateTaxDate.vue";

export default {
  name: "purchase-other-invoice-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
    DialogUpdateTaxDate,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    dialogTax: false,
    accounts: [],
    purchaseOtherInvoice: {},
    spk: "",
  }),
  computed: {
    ...mapState("external", ["forwarders"]),
  },
  methods: {
    async getPurchaseOtherInvoice() {
      await this.$store.dispatch("purchaseOtherInvoice/getById", this.id).then(async response => {
        this.purchaseOtherInvoice = response.data;
        await this.$store.dispatch("external/getSpkById", response.data.spkId).then(response => {
          this.spk = response.data[0].spk_num + response.data[0].spk_num_seq;
        });
      });
    },
    async getChartOfAccount() {
      const response = await this.$store.dispatch("chartOfAccount/getOtherAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    dialogDelete() {
      this.dialog = true;
    },
    deleteItem(id) {
      this.$store.dispatch("purchaseOtherInvoice/delete", id);
    },
    close() {
      this.dialog = this.dialogTax = false;
    },
    cancel() {
      this.getPurchaseOtherInvoice();
      this.edit = true;
    },
    openUpdateTaxDate() {
      this.dialogTax = true;
    },
    async updateTaxDate(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseOtherInvoice/updateTaxDate", item)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
          window.location.reload();
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getChartOfAccount(),
        await this.$store.dispatch("external/getForwarders"),
        this.getPurchaseOtherInvoice(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getPurchaseOtherInvoice();
  },
};
</script>

<style></style>
