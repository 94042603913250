<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Forwarder <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.vendorName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay For <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="listPayFor"
                  v-model="form.payFor"
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                  :rules="[v => !!v || 'Invoice Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Reimbursement Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.reimbursementNumber"
                  :rules="[v => !!v || 'Reimbursement Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Received Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceReceivedDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceReceivedDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceReceivedDate"
                    @input="menuInvoiceReceivedDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Term <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDuration"
                  suffix="days"
                  @input="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 1']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Due Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDueDate"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.percentagePpn > 0">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuTaxDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.taxDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.taxDate"
                    @input="menuTaxDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>DPP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.totalDpp"
                  type="number"
                  persistent-hint
                  :hint="formatPrice(form.totalDpp)"
                  :rules="[v => v >= 0 || 'DPP must more than equal 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        :items="accounts"
                        item-text="accountName"
                        return-object
                        v-model="account"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Account <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.accounts"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.accounts.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalIDR="{ item }">
                          {{ formatPrice(item.totalIDR) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-account
      :item="item"
      :index="index"
      :dialog="dialog"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
    ></dialog-account>
  </v-form>
</template>

<script>
import moment from "moment";
import DialogAccount from "@/components/DialogAccount";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "form-purchase-other-invoice",
  mixins: [fiscalMonthMixin],
  props: {
    form: Object,
    accounts: Array,
    forwarders: Array,
  },
  components: {
    "dialog-account": DialogAccount,
  },
  data: () => ({
    valid: true,
    menuInvoiceDate: false,
    menuInvoiceReceivedDate: false,
    menuTaxDate: false,
    dialog: false,
    item: {},
    index: -1,
    account: null,
    listPayFor: ["Pengiriman", "Tiket", "Sewa", "Handling", "Instalasi", "Pajak", "Lainnya"],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "nature",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount in RP",
        value: "totalIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {},

  watch: {},

  methods: {
    update() {
      if (this.$refs.form.validate() && this.form.accounts.length > 0) {
        if (this.isJournalBalance()) {
          this.$store.dispatch("purchaseOtherInvoice/update", this.form);
        } else {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Journal not balance",
          });
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Accounts is required",
        });
      }
    },
    isJournalBalance() {
      let totalDebit = 0;
      let totalCredit = 0;

      this.form.accounts.forEach(x => {
        if (x.nature === "Debit") {
          totalDebit += x.total;
        } else {
          totalCredit += x.total;
        }
      });

      return totalDebit - totalCredit === 0;
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceReceivedDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration() {
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.accounts.splice(index, 1);
        this.dialog = false;
      }
    },
    close() {
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.accounts.findIndex(x => x === item);
      this.dialog = true;
    },
    addAccount(item) {
      this.item = item;
      this.index = this.form.accounts.findIndex(x => x === item);
      this.dialog = true;
    },
    addItem(item) {
      if (this.index > -1) {
        this.items[this.index] = item;
      } else {
        this.items.push({ ...item });
      }
      this.account = null;
    },
  },
};
</script>

<style></style>
