<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Tax</span>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Tax Date</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-menu
                    v-model="menuTaxDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        dense
                        background-color="white"
                        v-model="form.taxDate"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.taxDate"
                      @input="menuTaxDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>DPP</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-text-field
                    outlined
                    flat
                    dense
                    background-color="white"
                    type="number"
                    v-model.number="form.totalDpp"
                    :rules="[v => v >= 0 || 'DPP must more than equal 0']"
                    persistent-hint
                    :hint="formatPrice(form.totalDpp)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Serial Number</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-text-field
                    outlined
                    flat
                    dense
                    background-color="white"
                    v-model="form.taxInvoiceSerialNumber"
                    :rules="[v => !!v || 'Serial Number is requirement']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Remark Correction</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-textarea
                    outlined
                    flat
                    background-color="white"
                    v-model="form.remarkCorrection"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Reported</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-checkbox v-model="form.report"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-update-tax-date",
  props: {
    dialog: Boolean,
    form: Object,
  },
  data() {
    return {
      menuTaxDate: false,
      valid: true,
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("save", this.form);
        this.$emit("close");
      }
    },
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
